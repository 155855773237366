require('./bootstrap');

jQuery(document).ready(function(){
    $('.filter').on("input", function (e) {

        const filter_text = $(this).val().toLowerCase()
        const target_list = $(this).data('target')

        if (filter_text.length === 0) {
            $(target_list + ' li').show()
        } else {
            $(target_list + ' li').each(function (index, element) {
                if ($(element).text().toLowerCase().indexOf(filter_text) >= 0) {
                    $(element).show()
                } else {
                    $(element).hide()
                }
            });
        }
    });
})
